@use '_modules' as *;
@use 'modules/colour';

service-centre-finder {
  --heading-colour: white;
  grid-gap: $gap2 $gutter !important;
  border: none !important;
  // min-height: 600px;
  align-items: center;

  h3 {
    @include heading-h2;
    margin-bottom: $gap2;
  }

  .text {
    color: $red;
    max-width: 500px;
  }

  form {
    h4 {
      @include heading-h3;
      color: $red;
      display: block;
      width: 100%;
      position: relative;
      line-height: 1.4;
      margin-bottom: $gap0-;
    }

    input {
      width: 100%;
      margin-top: $gap2;
      border: none;

      @include break-out(768) {
        width: 80%;
      }
    }

    button[type='submit'] {
      display: none;
    }
  }

  ul {
    @include list-ul {
      --list-style-colour: #{$red};
      --list-style-size: 1em;
      --line-style-spacing: 0.6em;
      color: white;
    }
  }

  > *:not(picture) {
    position: relative;
    z-index: 1;
  }

  .opening-times {
    color: white;
    display: flex;
    font-size: font-size(14);
    ul {
      list-style: none;
      li {
        font-size: font-size(14);
        padding-left: 0;
        &::before {
          content: none;
        }
      }
    }
  }

  .results {
    margin: $gap2 0;

    &:empty {
      display: none;
    }

    .error {
      color: $red;
    }

    address {
      border-radius: $border-radius;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      p {
        font-size: font-size(14);
        color: white;
        &.distance {
          font-style: italic;
          font-size: font-size(10);
          margin-top: $gap0;
          opacity: 0.7;
        }
        &.heading {
          @include heading-h4;
          margin-bottom: $gap0;
          font-weight: bold;
        }
        span {
          overflow: hidden;
          display: block;
        }
      }

      svg {
        width: 12px;
        height: 12px;
        fill: white;
        float: left;
        top: 5px;
        margin-right: 8px;
        position: relative;
      }
      > a {
        background-color: $secondary;
        transition: background-color $transition;
        border-radius: inherit;
        padding: 0 $gap0;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @include hover {
          background-color: darken($red, 10);
        }

        & > * {
          padding: $gap $gap0;
          &:first-child {
            border-right: 1px solid $primary;
          }

          &:last-child {
            border-left: 1px solid $primary;
          }
        }
      }

      @include break-out(768) {
        width: 80%;
      }
    }
  }

  &.loading {
    form::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: calc(100% + #{$gap0});
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid white;
      border-top-color: transparent;
      border-bottom-color: transparent;
      animation-name: rotate;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      @keyframes rotate {
        from {
          transform: rotate(180deg);
        }
        to {
          transform: rotate(0deg);
        }
      }
    }
  }

  .map {
    position: absolute;
    bottom: -30px;
    left: -50px;
    z-index: 3;

    @include break-in(980) {
      display: none;
    }

    svg {
      width: 100%;
      height: 800px;

      .st1 {
        fill: #f1f1f2;
      }

      .st8,
      .st17,
      .st19 {
        fill: #e5404b;
      }

      .st0,
      .st2,
      .st4,
      .st6,
      .st9,
      .st11,
      .st15,
      .st18,
      .st20,
      .st21,
      .st23,
      .st25 {
        fill: #ffffff;
      }

      .st3,
      .st5,
      .st7,
      .st10,
      .st12,
      .st14,
      .st16,
      .st22,
      .st24,
      .st26 {
        fill: #253068;
      }

      .st2 {
        clip-path: url(#SVGID_00000150069684003929109380000009343931992962722728_);
      }
      .st3 {
        clip-path: url(#SVGID_00000150069684003929109380000009343931992962722728_);
      }
      .st4 {
        clip-path: url(#SVGID_00000033367000806706957650000014665013643782968719_);
      }
      .st5 {
        clip-path: url(#SVGID_00000033367000806706957650000014665013643782968719_);
      }
      .st6 {
        clip-path: url(#SVGID_00000090252474677076718520000011195601274676352434_);
      }
      .st7 {
        clip-path: url(#SVGID_00000090252474677076718520000011195601274676352434_);
      }
      .st9 {
        clip-path: url(#SVGID_00000121244253395171394510000003838567394558771854_);
      }
      .st10 {
        clip-path: url(#SVGID_00000121244253395171394510000003838567394558771854_);
      }
      .st11 {
        clip-path: url(#SVGID_00000117665663997787401840000001037760982628412559_);
      }
      .st12 {
        clip-path: url(#SVGID_00000117665663997787401840000001037760982628412559_);
      }
      .st13 {
        clip-path: url(#SVGID_00000052104117246499784250000014449609706177469371_);
      }
      .st14 {
        clip-path: url(#SVGID_00000052104117246499784250000014449609706177469371_);
      }
      .st15 {
        clip-path: url(#SVGID_00000171683277033355057830000008007854479272258230_);
      }
      .st16 {
        clip-path: url(#SVGID_00000171683277033355057830000008007854479272258230_);
      }
      .st17 {
        clip-path: url(#SVGID_00000085241569841122841070000008427798458388486299_);
      }
      .st18 {
        clip-path: url(#SVGID_00000085241569841122841070000008427798458388486299_);
      }
      .st19 {
        clip-path: url(#SVGID_00000183950194555897609850000002080258297449685161_);
      }
      .st20 {
        clip-path: url(#SVGID_00000183950194555897609850000002080258297449685161_);
      }
      .st21 {
        clip-path: url(#SVGID_00000058562246678375135650000005584616311079567512_);
      }
      .st22 {
        clip-path: url(#SVGID_00000058562246678375135650000005584616311079567512_);
      }
      .st23 {
        clip-path: url(#SVGID_00000135656772395391829780000018058390647463039872_);
      }
      .st24 {
        clip-path: url(#SVGID_00000135656772395391829780000018058390647463039872_);
      }
      .st25 {
        clip-path: url(#SVGID_00000169558851855117145340000009826907307613408908_);
      }
      .st26 {
        clip-path: url(#SVGID_00000169558851855117145340000009826907307613408908_);
      }
    }
  }
}

/// Main Component -------------------------------------------------------------

main {
  .content-block[data-count='1'][data-blocks*='service-centre-finder'] {
    background-color: $blue;
  }
}

/// Root Component -------------------------------------------------------------

body > service-centre-finder {
  @include background-blowout;
  padding: 0;
  overflow-y: clip;
  background-color: $blue;

  .left {
    padding: $spacer 0;
    height: fit-content;
  }

  .right {
    align-self: baseline;
    height: 100%;

    .image {
      height: 100%;
      z-index: 1;
      min-width: 50vw;
      img {
        width: 100%;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 40%;
        background-image: linear-gradient(to right, $primary 30%, transparent);
        z-index: 2;
      }

      @include break-in(980) {
        width: 100vw;
        height: 33vh;
        margin-left: calc(#{$gutter} * -1);
        &::before {
          content: none;
        }
      }
    }
  }

  &:before {
    z-index: 0;
  }
  picture.image {
    opacity: 0.3;
    img {
      mix-blend-mode: soft-light;
    }
  }
  @include break-out(980) {
    &.layout-content-left-sidebar {
      grid-template-columns: repeat(2, 1fr);
    }

    .details {
      justify-content: flex-end;
      display: flex;
    }

    ul {
      &.split {
        max-width: var(--main-max-width);
        column-count: 2;
        column-gap: $gap;
        @include break-in(small-medium) {
          max-width: 100%;
          flex-grow: 1;
          margin-top: $gap2;
        }
      }
    }
  }
  @include break-in(small-medium) {
    ~ side-bar service-centre-finder {
      display: none !important;
    }
  }
}

/// Sidebar Component ----------------------------------------------------------

side-bar service-centre-finder {
  order: -1;
  h3 {
    @include heading-h3;
  }

  > picture.image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    mix-blend-mode: multiply;
  }

  overflow: hidden;

  .map {
    display: none;
  }

  .left {
    display: flex;
    flex-direction: column;
    h3 {
      order: 1;
    }
    form {
      order: 2;
    }
    .results {
      order: 4;
      & + .opening-times {
        order: 3;
        color: white;
        display: flex;
        flex-direction: column;
        padding: $gap0 $gap;
        border-radius: $border-radius;
        border: 1px dashed rgba(white, 0.4);
        background: linear-gradient(20deg, transparent, rgba(white, 0.1));
        margin-top: $gap;
        font-size: font-size(14);
        strong {
          color: $red-light;
        }
      }
    }
    .details {
      order: 5;
    }
  }

  .results address {
    a {
      grid-template-columns: 100%;
      & > * {
        padding: $gap0;
        &:first-child,
        &:last-child {
          border-right: none;
          border-left: none;
        }
      }

      .opening-times {
        display: none;
      }
    }
  }
}

/// Address AutoCommplete ------------------------------------------------------

.pac-container {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  // margin-top:calc(-1 * #{$border-radius});

  // Removing the Google logo almost certainly goes against the Terms of Use.
  // But it's so ugly and wasn't part of the design. Remove this line to get it back.
  &::after {
    content: initial;
  }
  .pac-item {
    border-top: initial;
    cursor: pointer;
    &:nth-child(odd) {
      background-color: $grey3;
    }
    @include hover {
      background-color: initial;
      &:nth-child(odd) {
        background-color: $grey3;
      }
      .pac-item-query {
        color: $red;
      }
    }
    .pac-item-query {
      color: $font-colour;
      .pac-matched {
        color: $red;
        font-weight: normal;
      }
    }
    .pac-icon {
      // background: initial;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 16'%3E%3Cpath fill='" + colour.encode($red) + "' d='M5.5 0C2.5 0 0 2.5 0 5.5 0 8.3 5.2 15.2 5.5 16c.3-.8 5.5-7.7 5.5-10.5C11 2.5 8.5 0 5.5 0zm0 8.6c-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3.1 3.1-3.1s3.1 1.4 3.1 3.1c0 1.7-1.4 3.1-3.1 3.1z'/%3E%3C/svg%3E");
      background-position: center center;
      background-size: 12px 12px;
      background-repeat: no-repeat;
      margin: 5px 6px 0 2px;

      // display:none;
    }
    &.pac-item-selected {
      background-color: $blue !important;
      .pac-item-query {
        &,
        .pac-matched {
          color: white;
        }
      }
    }
  }
}
