service-centre-finder {
  --heading-colour: white;
  grid-gap: calc(var(--gap) * 2) var(--gutter1) !important;
  border: none !important;
  align-items: center;
}
service-centre-finder h3 {
  font-size: 2em;
  line-height: 1.3;
  margin-bottom: calc(var(--gap) * 2);
}
service-centre-finder .text {
  color: #DF3035;
  max-width: 500px;
}
service-centre-finder form h4 {
  font-size: 1.5625em;
  line-height: 1.6;
  color: #DF3035;
  display: block;
  width: 100%;
  position: relative;
  line-height: 1.4;
  margin-bottom: calc(var(--gap) * -0.5);
}
service-centre-finder form input {
  width: 100%;
  margin-top: calc(var(--gap) * 2);
  border: none;
}
@media (min-width: 769px) {
  service-centre-finder form input {
    width: 80%;
  }
}
service-centre-finder form button[type=submit] {
  display: none;
}
service-centre-finder ul {
  --list-style-colour: #DF3035;
  --list-style-size: 1em;
  --line-style-spacing: 0.6em;
  color: white;
}
service-centre-finder ul > li::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle fill='%23DF3035' cx='8' cy='8' r='8'/%3E%3Cpath fill='none' stroke='white' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' d='M3.5 8.4l2.7 2.7 6.3-6.3'/%3E%3C/svg%3E");
}
service-centre-finder ul > li {
  position: relative;
  padding-left: calc(var(--list-style-size, 1em) * 1.8);
}
service-centre-finder ul > li + li {
  margin-top: var(--line-style-spacing, var(--gap));
}
service-centre-finder ul > li::before {
  display: inline-block;
  top: 0.1em;
  left: 0;
  position: absolute;
  width: var(--list-style-size, 1em);
  height: var(--list-style-size, 1em);
}
service-centre-finder > *:not(picture) {
  position: relative;
  z-index: 1;
}
service-centre-finder .opening-times {
  color: white;
  display: flex;
  font-size: 0.875em;
}
service-centre-finder .opening-times ul {
  list-style: none;
}
service-centre-finder .opening-times ul li {
  font-size: 0.875em;
  padding-left: 0;
}
service-centre-finder .opening-times ul li::before {
  content: none;
}
service-centre-finder .results {
  margin: calc(var(--gap) * 2) 0;
}
service-centre-finder .results:empty {
  display: none;
}
service-centre-finder .results .error {
  color: #DF3035;
}
service-centre-finder .results address {
  border-radius: var(--border-radius, 6px);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
service-centre-finder .results address p {
  font-size: 0.875em;
  color: white;
}
service-centre-finder .results address p.distance {
  font-style: italic;
  font-size: 0.625em;
  margin-top: calc(var(--gap) * 0.5);
  opacity: 0.7;
}
service-centre-finder .results address p.heading {
  font-size: 1.0625em;
  line-height: 1.6;
  margin-bottom: calc(var(--gap) * 0.5);
  font-weight: bold;
}
service-centre-finder .results address p span {
  overflow: hidden;
  display: block;
}
service-centre-finder .results address svg {
  width: 12px;
  height: 12px;
  fill: white;
  float: left;
  top: 5px;
  margin-right: 8px;
  position: relative;
}
service-centre-finder .results address > a {
  background-color: var(--secondary, #DF3035);
  transition: background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  border-radius: inherit;
  padding: 0 calc(var(--gap) * 0.5);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (hover: hover) {
  service-centre-finder .results address > a:hover {
    background-color: #bf1d22;
  }
}
service-centre-finder .results address > a > * {
  padding: var(--gap) calc(var(--gap) * 0.5);
}
service-centre-finder .results address > a > *:first-child {
  border-right: 1px solid var(--primary, #131f6b);
}
service-centre-finder .results address > a > *:last-child {
  border-left: 1px solid var(--primary, #131f6b);
}
@media (min-width: 769px) {
  service-centre-finder .results address {
    width: 80%;
  }
}
service-centre-finder.loading form::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(100% + calc(var(--gap) * 0.5));
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid white;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation-name: rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}
@keyframes rotate {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
service-centre-finder .map {
  position: absolute;
  bottom: -30px;
  left: -50px;
  z-index: 3;
}
@media (max-width: 980px) {
  service-centre-finder .map {
    display: none;
  }
}
service-centre-finder .map svg {
  width: 100%;
  height: 800px;
}
service-centre-finder .map svg .st1 {
  fill: #f1f1f2;
}
service-centre-finder .map svg .st8,
service-centre-finder .map svg .st17,
service-centre-finder .map svg .st19 {
  fill: #e5404b;
}
service-centre-finder .map svg .st0,
service-centre-finder .map svg .st2,
service-centre-finder .map svg .st4,
service-centre-finder .map svg .st6,
service-centre-finder .map svg .st9,
service-centre-finder .map svg .st11,
service-centre-finder .map svg .st15,
service-centre-finder .map svg .st18,
service-centre-finder .map svg .st20,
service-centre-finder .map svg .st21,
service-centre-finder .map svg .st23,
service-centre-finder .map svg .st25 {
  fill: #ffffff;
}
service-centre-finder .map svg .st3,
service-centre-finder .map svg .st5,
service-centre-finder .map svg .st7,
service-centre-finder .map svg .st10,
service-centre-finder .map svg .st12,
service-centre-finder .map svg .st14,
service-centre-finder .map svg .st16,
service-centre-finder .map svg .st22,
service-centre-finder .map svg .st24,
service-centre-finder .map svg .st26 {
  fill: #253068;
}
service-centre-finder .map svg .st2 {
  clip-path: url(#SVGID_00000150069684003929109380000009343931992962722728_);
}
service-centre-finder .map svg .st3 {
  clip-path: url(#SVGID_00000150069684003929109380000009343931992962722728_);
}
service-centre-finder .map svg .st4 {
  clip-path: url(#SVGID_00000033367000806706957650000014665013643782968719_);
}
service-centre-finder .map svg .st5 {
  clip-path: url(#SVGID_00000033367000806706957650000014665013643782968719_);
}
service-centre-finder .map svg .st6 {
  clip-path: url(#SVGID_00000090252474677076718520000011195601274676352434_);
}
service-centre-finder .map svg .st7 {
  clip-path: url(#SVGID_00000090252474677076718520000011195601274676352434_);
}
service-centre-finder .map svg .st9 {
  clip-path: url(#SVGID_00000121244253395171394510000003838567394558771854_);
}
service-centre-finder .map svg .st10 {
  clip-path: url(#SVGID_00000121244253395171394510000003838567394558771854_);
}
service-centre-finder .map svg .st11 {
  clip-path: url(#SVGID_00000117665663997787401840000001037760982628412559_);
}
service-centre-finder .map svg .st12 {
  clip-path: url(#SVGID_00000117665663997787401840000001037760982628412559_);
}
service-centre-finder .map svg .st13 {
  clip-path: url(#SVGID_00000052104117246499784250000014449609706177469371_);
}
service-centre-finder .map svg .st14 {
  clip-path: url(#SVGID_00000052104117246499784250000014449609706177469371_);
}
service-centre-finder .map svg .st15 {
  clip-path: url(#SVGID_00000171683277033355057830000008007854479272258230_);
}
service-centre-finder .map svg .st16 {
  clip-path: url(#SVGID_00000171683277033355057830000008007854479272258230_);
}
service-centre-finder .map svg .st17 {
  clip-path: url(#SVGID_00000085241569841122841070000008427798458388486299_);
}
service-centre-finder .map svg .st18 {
  clip-path: url(#SVGID_00000085241569841122841070000008427798458388486299_);
}
service-centre-finder .map svg .st19 {
  clip-path: url(#SVGID_00000183950194555897609850000002080258297449685161_);
}
service-centre-finder .map svg .st20 {
  clip-path: url(#SVGID_00000183950194555897609850000002080258297449685161_);
}
service-centre-finder .map svg .st21 {
  clip-path: url(#SVGID_00000058562246678375135650000005584616311079567512_);
}
service-centre-finder .map svg .st22 {
  clip-path: url(#SVGID_00000058562246678375135650000005584616311079567512_);
}
service-centre-finder .map svg .st23 {
  clip-path: url(#SVGID_00000135656772395391829780000018058390647463039872_);
}
service-centre-finder .map svg .st24 {
  clip-path: url(#SVGID_00000135656772395391829780000018058390647463039872_);
}
service-centre-finder .map svg .st25 {
  clip-path: url(#SVGID_00000169558851855117145340000009826907307613408908_);
}
service-centre-finder .map svg .st26 {
  clip-path: url(#SVGID_00000169558851855117145340000009826907307613408908_);
}

main .content-block[data-count="1"][data-blocks*=service-centre-finder] {
  background-color: #131f6b;
}

body > service-centre-finder {
  padding: 0;
  overflow-y: clip;
  background-color: #131f6b;
}
body > service-centre-finder:before {
  content: "";
  background-color: inherit;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  transform: translateX(var(--translateX, -50%));
}
body > service-centre-finder .left {
  padding: var(--spacer1) 0;
  height: fit-content;
}
body > service-centre-finder .right {
  align-self: baseline;
  height: 100%;
}
body > service-centre-finder .right .image {
  height: 100%;
  z-index: 1;
  min-width: 50vw;
}
body > service-centre-finder .right .image img {
  width: 100%;
}
body > service-centre-finder .right .image::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40%;
  background-image: linear-gradient(to right, var(--primary, #131f6b) 30%, transparent);
  z-index: 2;
}
@media (max-width: 980px) {
  body > service-centre-finder .right .image {
    width: 100vw;
    height: 33vh;
    margin-left: calc(var(--gutter1) * -1);
  }
  body > service-centre-finder .right .image::before {
    content: none;
  }
}
body > service-centre-finder:before {
  z-index: 0;
}
body > service-centre-finder picture.image {
  opacity: 0.3;
}
body > service-centre-finder picture.image img {
  mix-blend-mode: soft-light;
}
@media (min-width: 981px) {
  body > service-centre-finder.layout-content-left-sidebar {
    grid-template-columns: repeat(2, 1fr);
  }
  body > service-centre-finder .details {
    justify-content: flex-end;
    display: flex;
  }
  body > service-centre-finder ul.split {
    max-width: var(--main-max-width);
    column-count: 2;
    column-gap: var(--gap);
  }
}
@media (min-width: 981px) and (max-width: 980px) {
  body > service-centre-finder ul.split {
    max-width: 100%;
    flex-grow: 1;
    margin-top: calc(var(--gap) * 2);
  }
}
@media (max-width: 980px) {
  body > service-centre-finder ~ side-bar service-centre-finder {
    display: none !important;
  }
}

side-bar service-centre-finder {
  order: -1;
  overflow: hidden;
}
side-bar service-centre-finder h3 {
  font-size: 1.5625em;
  line-height: 1.6;
}
side-bar service-centre-finder > picture.image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  mix-blend-mode: multiply;
}
side-bar service-centre-finder .map {
  display: none;
}
side-bar service-centre-finder .left {
  display: flex;
  flex-direction: column;
}
side-bar service-centre-finder .left h3 {
  order: 1;
}
side-bar service-centre-finder .left form {
  order: 2;
}
side-bar service-centre-finder .left .results {
  order: 4;
}
side-bar service-centre-finder .left .results + .opening-times {
  order: 3;
  color: white;
  display: flex;
  flex-direction: column;
  padding: calc(var(--gap) * 0.5) var(--gap);
  border-radius: var(--border-radius, 6px);
  border: 1px dashed rgba(255, 255, 255, 0.4);
  background: linear-gradient(20deg, transparent, rgba(255, 255, 255, 0.1));
  margin-top: var(--gap);
  font-size: 0.875em;
}
side-bar service-centre-finder .left .results + .opening-times strong {
  color: #e44f53;
}
side-bar service-centre-finder .left .details {
  order: 5;
}
side-bar service-centre-finder .results address a {
  grid-template-columns: 100%;
}
side-bar service-centre-finder .results address a > * {
  padding: calc(var(--gap) * 0.5);
}
side-bar service-centre-finder .results address a > *:first-child, side-bar service-centre-finder .results address a > *:last-child {
  border-right: none;
  border-left: none;
}
side-bar service-centre-finder .results address a .opening-times {
  display: none;
}

.pac-container {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom-left-radius: var(--border-radius, 6px);
  border-bottom-right-radius: var(--border-radius, 6px);
}
.pac-container::after {
  content: initial;
}
.pac-container .pac-item {
  border-top: initial;
  cursor: pointer;
}
.pac-container .pac-item:nth-child(odd) {
  background-color: #F4F4F4;
}
@media (hover: hover) {
  .pac-container .pac-item:hover {
    background-color: initial;
  }
  .pac-container .pac-item:hover:nth-child(odd) {
    background-color: #F4F4F4;
  }
  .pac-container .pac-item:hover .pac-item-query {
    color: #DF3035;
  }
}
.pac-container .pac-item .pac-item-query {
  color: #6A6A6A;
}
.pac-container .pac-item .pac-item-query .pac-matched {
  color: #DF3035;
  font-weight: normal;
}
.pac-container .pac-item .pac-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 16'%3E%3Cpath fill='%23DF3035' d='M5.5 0C2.5 0 0 2.5 0 5.5 0 8.3 5.2 15.2 5.5 16c.3-.8 5.5-7.7 5.5-10.5C11 2.5 8.5 0 5.5 0zm0 8.6c-1.7 0-3.1-1.4-3.1-3.1 0-1.7 1.4-3.1 3.1-3.1s3.1 1.4 3.1 3.1c0 1.7-1.4 3.1-3.1 3.1z'/%3E%3C/svg%3E");
  background-position: center center;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  margin: 5px 6px 0 2px;
}
.pac-container .pac-item.pac-item-selected {
  background-color: #131f6b !important;
}
.pac-container .pac-item.pac-item-selected .pac-item-query,
.pac-container .pac-item.pac-item-selected .pac-item-query .pac-matched {
  color: white;
}